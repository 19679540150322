class TimeHelper {
  // HH.mm to minute
  public static formattingHoursMinuteToMinute(value: number): number {
    const hours = Math.floor(value);
    const minute = Math.floor((value % 1) * 100);
    return (hours * 60) + minute;
  }

  // minute to HH.mm
  public static formattingMinuteToHoursMinute(value: number): string {
    const hours = Math.floor(value / 60);
    const minute = Math.floor((value % 60));
    if (minute === 0) {
      return `${hours}`;
    }
    if (minute < 10) {
      return `${hours}.0${minute}`;
    }
    return `${hours}.${minute}`;
  }

  // HH.mm to HH.mm
  public static fixFormatHoursMinute(value: number): string {
    const hours = Math.floor(value);
    const minute = Math.round((value % 1) * 100);
    return this.formattingMinuteToHoursMinute((hours * 60) + minute);
  }

  // HH.mm to HH.mm
  public static fixFormatHoursMinuteNumber(value: number): number {
    const hours = Math.floor(value);
    const minute = Math.round((value % 1) * 100);
    return parseFloat(this.formattingMinuteToHoursMinute((hours * 60) + minute));
  }

  // seconde to HH.mm
  public static formattingSecondeToHoursMinute(value: number): string {
    const hours = Math.floor(value / 3600);
    const minute = Math.floor((value % 3600) / 60);
    return `${hours}.${minute}`;
  }
}

export default TimeHelper;
