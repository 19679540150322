import UserEntity from '@/class/UserEntity';
import RoutingDayPlanning from '@/class/RoutingDayPlanning';
import RoutingWeekPlanning from '@/class/RoutingWeekPlanning';
import AffaireStore from '@/store/AffaireStore';
import AffaireRank from '@/class/AffaireRank';

class DayPlanningHelper {
  private static convertArrayToRoutingDayPlanning(
    weekPlanning: RoutingWeekPlanning,
    user: UserEntity,
    data: any,
  ): RoutingDayPlanning {
    const routingDayPlanning = new RoutingDayPlanning(weekPlanning, user, new Date(data.startDate));
    routingDayPlanning.internalId = data.id;
    routingDayPlanning.minutes = data.minutes;
    return routingDayPlanning;
  }
  /*
  public static getDayPlanningRoutingByOperatorAndDate(
    user: UserEntity,
    date: Date,
    clb: undefined | ((array: Array<RoutingDayPlanning>) => void) = undefined,
  ) {
    const result = new Array<RoutingDayPlanning>();
    const startDate = new Date(date);
    startDate.setHours(0, 0, 0, 0);
    const endDate = new Date(date);
    endDate.setHours(23, 59, 59, 0);
    MainEndpoint.getAxiosInstance().post('/day-planning/date-operator', {
      operator: user.User,
      startDate: startDate.toUTCString(),
      endDate: endDate.toUTCString(),
    }).then((response) => {
      // Guard
      if (response.data === undefined) return;
      if (response.data.length === 0) {
        if (clb !== undefined) {
          clb(result);
        }
        return;
      }

      const routing = RoutingHelper.getRoutingById(response.data[0].idRouting);
      if (routing === undefined) {
        Notify.notifyWarning('Une tâche n\'a pas pu être
        affiché car son affaire n\'a pas été chargé dans l\'onglet recherche');
        return;
      }
      response.data.forEach((data: any) => {
        const routingDayPlanning = DayPlanningHelper
          .convertArrayToRoutingDayPlanning(routing, user, data);
        result.push(routingDayPlanning);
      });

      if (clb !== undefined) {
        clb(result);
      }
    });
  }

  public static getRoutingsDayPlanningByRouting(
    routing: RankRouting,
    clb: undefined | ((array: Array<RoutingDayPlanning>) => void) = undefined,
  ) {
    const result = new Array<RoutingDayPlanning>();

    MainEndpoint.getAxiosInstance().get(`/day-planning/routing/${routing.IdRouting}`)
      .then((response) => {
        // Guard
        if (response.data === undefined) return;
        if (response.data.length === 0) {
          if (clb !== undefined) {
            clb(result);
          }
          return;
        }
        response.data.forEach((data: any) => {
          const user = UserHelper.getUserById(data.operator);
          if (user === undefined) return;
          const routingDayPlanning = DayPlanningHelper
            .convertArrayToRoutingDayPlanning(routing, user, data);
          result.push(routingDayPlanning);
        });

        if (clb !== undefined) {
          clb(result);
        }
      });
  } */

  public static getRoutingWeekPlanningByDateAndRank(rank: AffaireRank, date: Date) {
    const result = new Array<RoutingWeekPlanning>();
    rank.Postes.forEach((poste) => {
      poste.routingsWeekPlanning.forEach((rwp) => {
        if (rwp.date.getTime() === date.getTime()) {
          result.push(rwp);
        }
      });
    });

    // Recusivity
    rank.Childs.forEach((rChild) => {
      result.push(...DayPlanningHelper.getRoutingWeekPlanningByDateAndRank(rChild, date));
    });
    return result;
  }

  public static getRoutingsWeekPlanningByDate(date: Date): Array<RoutingWeekPlanning> {
    const result = new Array<RoutingWeekPlanning>();
    const affaireStore = AffaireStore();
    const formattedDate = new Date(date);
    formattedDate.setHours(0, 0, 0, 0);
    affaireStore.affaires.forEach((affaire) => {
      affaire.Ranks.forEach((rank) => {
        result.push(...DayPlanningHelper.getRoutingWeekPlanningByDateAndRank(
          rank as AffaireRank,
          formattedDate,
        ));
      });
      affaire.regroupedRoutings.forEach((rr) => {
        rr.routingsWeekPlanning.forEach((rwp) => {
          if (rwp.date.getTime() === formattedDate.getTime()) {
            result.push(rwp as RoutingWeekPlanning);
          }
        });
      });
    });
    return result;
  }
}

export default DayPlanningHelper;
