
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CameraShooter',
  emits: ['validatePhoto'],
  data() {
    return {
      isCameraOpen: false,
      isPhotoTaken: false,
      isShotPhoto: false,
      isLoading: false,
      error: false,
    };
  },
  methods: {
    startCamera() {
      this.isCameraOpen = true;
      this.createCameraElement();
    },
    stopCamera() {
      this.isCameraOpen = false;
      this.isPhotoTaken = false;
      this.isShotPhoto = false;
      this.stopCameraStream();
    },
    createCameraElement() {
      this.isLoading = true;

      const constraints = {
        audio: false,
        video: true,
      };

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          this.isLoading = false;
          (this.$refs.camera as HTMLVideoElement).srcObject = stream;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
          this.error = true;
        });
    },

    stopCameraStream() {
      if (this.error) return;
      const tracks = ((this.$refs.camera as HTMLVideoElement).srcObject as MediaStream).getTracks();

      tracks.forEach((track) => {
        track.stop();
      });
    },

    takePhoto() {
      if (!this.isPhotoTaken) {
        this.isShotPhoto = true;

        const FLASH_TIMEOUT = 50;

        setTimeout(() => {
          this.isShotPhoto = false;
        }, FLASH_TIMEOUT);
      }

      this.isPhotoTaken = !this.isPhotoTaken;

      const context = (this.$refs.canvas as HTMLCanvasElement).getContext('2d');
      if (context === null) return;
      context.drawImage((this.$refs.camera as HTMLVideoElement), 0, 0, 680, 510);
    },

    emitPhoto() {
      const canvas = (document.getElementById('photoTaken') as HTMLCanvasElement)
        .toDataURL('image/jpeg'); // .replace('image/jpeg', 'image/octet-stream');

      this.$emit('validatePhoto', canvas);
    },
  },
  mounted() {
    this.startCamera();
  },
  beforeUnmount() {
    this.stopCamera();
  },
});
