
import { defineComponent } from 'vue';
import RoutingDayPlanning from '@/class/RoutingDayPlanning';
import RankHelper from '@/helper/RankHelper';
import Notiflix from 'notiflix';
import TreeView from '@/components/tree-view/TreeView.vue';
import CameraShooter from '@/components/camera-shooter/CameraShooter.vue';
import mainEndpoint from '@/endpoints/main';
import GroupedRouting from '@/class/GroupedRouting';
import TimeHelper from '@/helper/TimeHelper';

export default defineComponent({
  name: 'DayWeekPlanningDetail',
  components: {
    TreeView,
    CameraShooter,
  },

  emits: ['close'],
  props: {
    dayRoutingPlanning: {
      type: RoutingDayPlanning,
      required: true,
    },
  },
  data() {
    return {
      showSchema: false,
      toggleComment: false,
      timestamp: '',
      idInterval: 0,
      percent: 0,
      overtime: 0,
      takePicture: false,
      images: new Array<{ id: string, base64: string }>(),
      showImage: undefined as undefined | { id: string, base64: string },
    };
  },
  computed: {
    TimeHelper() {
      return TimeHelper;
    },
    isGroupedRouting() {
      return this.dayRoutingPlanning.weekPlanning.baseRouting instanceof GroupedRouting;
    },
    multipleOperators() {
      let subTask = 0;
      this.dayRoutingPlanning.weekPlanning.baseRouting.routingsWeekPlanning.forEach((rwp) => {
        subTask += rwp.routingsDayPlanning.length;
      });
      return subTask !== 1;
    },
  },
  methods: {
    showManuTimeWithCoef(time: number) {
      const minute = TimeHelper.formattingHoursMinuteToMinute(time);
      if (minute < 60) {
        return `${minute} min`;
      }
      return `${TimeHelper.formattingMinuteToHoursMinute(minute)} h`;
    },
    getPostesToShow() {
      const postes = this.dayRoutingPlanning.weekPlanning.baseRouting.Rank?.Postes;
      if (postes === undefined) return [];
      return postes.filter((p) => p.CostCenterObject?.Code !== 'NOMEN');
    },
    changeImage(direction: 'previous' | 'next') {
      if (this.showImage === undefined) return;
      const currentIndex = this.images.indexOf(this.showImage);
      const maxIndex = this.images.length - 1;
      if (direction === 'previous') {
        if (currentIndex - 1 < 0) {
          this.showImage = this.images[maxIndex];
        } else {
          this.showImage = this.images[currentIndex - 1];
        }
      }
      if (direction === 'next') {
        if (currentIndex + 1 > maxIndex) {
          this.showImage = this.images[0];
        } else {
          this.showImage = this.images[currentIndex + 1];
        }
      }
    },
    deleteImage() {
      if (this.showImage === undefined) return;
      const imageId = this.showImage.id;
      Notiflix.Confirm.show(
        'Supprimer',
        'Voulez-vous vraiment supprimer cette image ?',
        'Oui',
        'Annuler',

        () => {
          this.showImage = undefined;
          mainEndpoint.getAxiosInstance()
            .delete(`day-planning/image/delete/${imageId}`).then(() => {
              this.images = this.images.filter((image) => image.id !== imageId);
            });
        },
        undefined,
      );
    },
    addPhoto(internalId: number, photo: {id: string, base64: string}) {
      if (internalId !== this.dayRoutingPlanning.internalId) return;
      this.images.push(photo);
    },
    retrievePhotos() {
      this.images = new Array<{id: string, base64: string}>();
      const internalId = this.dayRoutingPlanning.internalId;
      mainEndpoint.getAxiosInstance()
        .get(`day-planning/images-list/${this.dayRoutingPlanning.internalId}`)
        .then((response) => {
          if (internalId !== this.dayRoutingPlanning.internalId) return;
          response.data.forEach((imageId: number) => {
            mainEndpoint.getAxiosInstance().get(`day-planning/image/${imageId}`)
              .then((responseImage) => {
                const currentInternalId = this.dayRoutingPlanning.internalId;
                if (currentInternalId === undefined) return;
                this.addPhoto(currentInternalId, responseImage.data);
              });
          });
        });
    },
    uploadPhoto(picture: string) {
      this.takePicture = false;
      mainEndpoint.getAxiosInstance().post(
        `day-planning/image/add/${this.dayRoutingPlanning.internalId}`,
        {
          base64: picture,
        },
      ).then((response) => {
        if (this.dayRoutingPlanning.internalId === undefined) return;
        this.addPhoto(this.dayRoutingPlanning.internalId, response.data);
      });
    },
    getPercentDone() {
      const startDateClocking = this.dayRoutingPlanning.startDateClocking ?? 0;
      let secondsSinceLastStart = 0;
      if (startDateClocking !== 0) {
        secondsSinceLastStart = (Date.now() - startDateClocking.getTime()) / 1000;
      }
      const totalSeconds = this.dayRoutingPlanning.totalTimeClocking + secondsSinceLastStart;
      const percent = (totalSeconds * 100) / (this.dayRoutingPlanning.minutes * 60);
      return Math.min(100, percent);
    },
    getOvertime() {
      const startDateClocking = this.dayRoutingPlanning.startDateClocking ?? 0;
      let secondsSinceLastStart = 0;
      if (startDateClocking !== 0) {
        secondsSinceLastStart = (Date.now() - startDateClocking.getTime()) / 1000;
      }
      const totalSeconds = this.dayRoutingPlanning.totalTimeClocking + secondsSinceLastStart;
      if (totalSeconds <= this.dayRoutingPlanning.minutes * 60 || this.dayRoutingPlanning.minutes * 60 <= this.dayRoutingPlanning.totalTimeClocking) {
        return 0;
      }
      return totalSeconds - this.dayRoutingPlanning.minutes * 60;
    },
    getColorLevel(level: number) {
      return RankHelper.getColorLevel(level);
    },
    updateTimestamp() {
      const now = new Date();
      this.timestamp = now.toLocaleString();
    },
    pauseClocking() {
      Notiflix.Confirm.show(
        'Pointage',
        'Voulez-vous vraiment mettre en pause le pointage ?',
        'Oui',
        'Annuler',

        () => {
          // eslint-disable-next-line vue/no-mutating-props
          this.dayRoutingPlanning.pauseClocking();
        },
        undefined,
      );
    },
    finishClocking() {
      Notiflix.Confirm.show(
        'Pointage',
        'Voulez-vous vraiment finir le pointage ?',
        'Oui',
        'Annuler',
        () => {
          this.dayRoutingPlanning.finishClocking();
        },
        undefined,
      );
    },
    startClocking() {
      Notiflix.Confirm.show(
        'Pointage',
        'Voulez-vous vraiment démarrer le pointage ?',
        'Oui',
        'Annuler',

        () => {
          this.dayRoutingPlanning.startClocking();
          this.dayRoutingPlanning.updateChildNotFinished();
        },
        undefined,
      );
    },
  },
  watch: {
    // Update l'avancement si le props est changé
    // (si l'utilisateur selectionne une nouvelle tache sans fermer le modal)
    dayRoutingPlanning() {
      this.percent = this.getPercentDone();
      this.overtime = this.getOvertime();
      this.showSchema = false;
      this.takePicture = false;
      this.showImage = undefined;
      this.retrievePhotos();
    },
  },
  created() {
    this.updateTimestamp();
    this.percent = this.getPercentDone();
    this.overtime = this.getOvertime();
    this.idInterval = setInterval(() => {
      this.updateTimestamp();
      if (this.dayRoutingPlanning.isCurrentlyDoing) {
        this.percent = this.getPercentDone();
        this.overtime = this.getOvertime();
      }
    }, 1000);
  },
  mounted() {
    this.retrievePhotos();
  },
  unmounted() {
    clearInterval(this.idInterval);
  },
});
