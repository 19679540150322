

import { defineComponent } from 'vue';
import DraggableWindowStore from '@/store/DraggableWindowStore';
import draggableWindowsShowBus from '@/bus/draggableWindowsShowBus';
import GanttResizeWindow from '@/bus/ganttResizeWindow';

export default defineComponent({
  name: 'DraggableWindow',
  emits: ['onOpen', 'onClose', 'onAllClose'],
  data() {
    return {
      isOpen: false,
      zindex: DraggableWindowStore().currentIndex,
      positions: {
        clientX: 0,
        clientY: 0,
        movementX: 0,
        movementY: 0,
      },
      widthEdited: this.width,
      heightEdited: this.height,
    };
  },
  props: {
    width: {
      type: Number,
      default: () => 200,
    },
    height: {
      type: Number,
      default: () => 200,
    },
    resizable: {
      type: Boolean,
      default: () => true,
    },
    windowTitle: {
      type: String,
      default: () => 'No title',
    },
    offsetWindowLeft: {
      type: Number,
      default: () => 35,
    },
    offsetWindowTop: {
      type: Number,
      default: () => 35,
    },
    maxHeight: {
      type: Number,
      default: () => document.body.clientHeight,
    },
    maxWidth: {
      type: Number,
      default: () => document.body.clientWidth,
    },
    minHeight: {
      type: Number,
      default: () => 100,
    },
    minWidth: {
      type: Number,
      default: () => 100,
    },
    allowToCloseAll: {
      type: Boolean,
      default: () => false,
    },
  },
  methods: {
    focusWindow() {
      const draggableStore = DraggableWindowStore();
      draggableStore.currentIndex += 1;
      this.zindex = draggableStore.currentIndex;
    },
    close() {
      this.isOpen = false;
      this.positions.clientX = 0;
      this.positions.clientY = 0;
      this.positions.movementX = 0;
      this.positions.movementY = 0;
      this.$emit('onClose');
    },
    closeAll() {
      this.isOpen = false;
      this.positions.clientX = 0;
      this.positions.clientY = 0;
      this.positions.movementX = 0;
      this.positions.movementY = 0;
      this.$emit('onAllClose');
    },
    open() {
      this.isOpen = true;
      const draggableContainer: HTMLDivElement = this.$refs.draggableContainer as HTMLDivElement;
      // set the element's new position:
      draggableContainer.style.top = `${this.offsetWindowTop}px`;
      draggableContainer.style.left = `${this.offsetWindowLeft}px`;
      this.$emit('onOpen');
    },
    dragMouseDown(event: MouseEvent) {
      event.preventDefault();
      // get the mouse cursor position at startup:
      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;
      document.onmousemove = this.elementDrag;
      document.onmouseup = this.closeDragElement;
    },
    resizeMouseDown(event: MouseEvent) {
      event.preventDefault();
      // get the mouse cursor position at startup:
      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;
      document.onmousemove = this.resizeDrag;
      document.onmouseup = this.closeDragElement;
    },
    resizeDrag(event: MouseEvent) {
      event.preventDefault();
      if (
        event.clientX < 1
        || event.clientX > document.body.clientWidth
        || event.clientY < 1
        || event.clientY > document.body.clientHeight
      ) {
        return;
      }
      this.positions.movementX = this.positions.clientX - event.clientX;
      this.positions.movementY = this.positions.clientY - event.clientY;
      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;

      this.widthEdited -= this.positions.movementX;
      this.widthEdited = Math.max(this.widthEdited, this.minWidth);
      this.widthEdited = Math.min(this.widthEdited, window.innerWidth);

      this.heightEdited -= this.positions.movementY;
      this.heightEdited = Math.min(this.heightEdited, this.maxHeight);
      this.heightEdited = Math.max(this.heightEdited, this.minHeight);
      GanttResizeWindow.trigger('resize', [this.heightEdited, this.widthEdited]);
    },
    elementDrag(event: MouseEvent) {
      event.preventDefault();
      if (
        event.clientX < 1
        || event.clientX > document.body.clientWidth
        || event.clientY < 1
        || event.clientY > document.body.clientHeight
      ) {
        return;
      }

      this.positions.movementX = this.positions.clientX - event.clientX;
      this.positions.movementY = this.positions.clientY - event.clientY;
      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;
      const draggableContainer: HTMLDivElement = this.$refs.draggableContainer as HTMLDivElement;

      // set the element's new position:
      draggableContainer.style.top = `${draggableContainer.offsetTop - this.positions.movementY}px`;
      draggableContainer.style.left = `${draggableContainer.offsetLeft - this.positions.movementX}px`;
    },
    closeDragElement() {
      document.onmouseup = null;
      document.onmousemove = null;
    },
  },
  mounted() {
    this.open();
    this.focusWindow();
    draggableWindowsShowBus.on('open', (open) => {
      this.isOpen = open;
    });
    draggableWindowsShowBus.on('close', (close) => {
      this.isOpen = close;
    });
    GanttResizeWindow.trigger('resize', [this.heightEdited, this.widthEdited]);
  },
  unmounted() {
    draggableWindowsShowBus.off('open', (open) => {
      this.isOpen = open;
    });
    draggableWindowsShowBus.off('close', (close) => {
      this.isOpen = close;
    });
  },
});

