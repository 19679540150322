

import CostCenterHelper from '@/helper/CostCenterHelper';
import CostCenter from '@/class/CostCenter';
import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import UserAuthStore from '@/store/UserAuthStore';
import DateHelper from '@/helper/DateHelper';
import RoutingWeekPlanning from '@/class/RoutingWeekPlanning';
import DayPlanningHelper from '@/helper/DayPlanningHelper';
import RoutingDayPlanning from '@/class/RoutingDayPlanning';
import { clone } from 'lodash';
import User from '@/class/User';
import RankRouting from '@/class/RankRouting';
import DayWeekPlanningDetail from '@/components/operator/DayWeekPlanningDetail.vue';
import AffaireStore from '@/store/AffaireStore';
import CostCenterStore from '@/store/CostCenterStore';
import UserStore from '@/store/UserStore';
import UserAuthManager from '@/class/UserAuthManager';
import GroupedRouting from '@/class/GroupedRouting';
import UserEntity from '@/class/UserEntity';
import UserHelper from '@/helper/UserHelper';
import DraggableWindow from '@/components/draggable-window/DraggableWindow.vue';
import Notiflix, { Notify } from 'notiflix';
import moment from 'moment';
import { DatePicker } from 'v-calendar';

export default defineComponent({
  name: 'OperateurVue',
  components: { DraggableWindow, DayWeekPlanningDetail, DatePicker },
  props: {
    standalone: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    costCenterHelper() {
      return CostCenterHelper;
    },
    costCenterToShow(): Array<CostCenter> {
      return CostCenterHelper.getCostCenterToShow();
    },
    ...mapState(
      UserAuthStore,
      { user: 'user' },
    ),
    ...mapState(
      AffaireStore,
      { affaires: 'affaires' },
    ),
    routingsWeekPlanning(): Array<RoutingWeekPlanning> {
      return DayPlanningHelper.getRoutingsWeekPlanningByDate(this.selectedDay);
    },

    routingsDayPlanning(): Array<RoutingDayPlanning> {
      const routingsDayPlanning = new Array<RoutingDayPlanning>();
      let user = this.user;
      if (user === undefined) {
        return routingsDayPlanning;
      }

      if ((process.env.VUE_APP_ENV.toLowerCase() === 'dev'
        || process.env.VUE_APP_ENV.toLowerCase() === 'preprod') && !this.standalone) {
        user = clone(this.user) as User;
        user.username = 'ADMIN';
      } else {
        user.username = user.username.toUpperCase();
      }

      this.routingsWeekPlanning.forEach((rwp) => {
        routingsDayPlanning.push(...rwp.routingsDayPlanning.filter(
          (rdp) => rdp.operator.User === (user as User).username,
        ));
      });

      if (!this.options.filtre) {
        return routingsDayPlanning;
      }

      return routingsDayPlanning.filter((rdp) => {
        const routing = rdp.weekPlanning.baseRouting;
        if (routing instanceof RankRouting) {
          return routing.CostCenterObject?.Designation
            === this.costCenterToShow[this.options.selectedFilter].Designation;
        }
        return false;
      });
    },
  },
  data() {
    return {
      selectedDay: new Date(),
      options: {
        filtre: false,
        selectedFilter: 0,
      },
      routingSearch: undefined as undefined | number,
      selectedRouting: undefined as undefined | RoutingDayPlanning,
      routing: undefined as undefined | any,
      routingWindow: {
        open: false as boolean,
        timeToAdd: 1.0 as number,
        isfinish: false as boolean,
        operator: undefined as undefined | UserEntity,
        routingPlanning: new RankRouting() as GroupedRouting | RankRouting,
        hour: '08:00' as string,
        costCenter: 'LASER' as string,
        color: 'red' as string,
        left: (document.documentElement.clientWidth / 2) - (400 / 2) as number,
      },
    };
  },
  methods: {
    rangeNewRouting() {
      const hoursSplit = moment(this.routingWindow.timeToAdd, 'HH:mm');
      const endHours = moment(this.routingWindow.hour, 'HH:mm');
      endHours.add(hoursSplit.hour(), 'hours');
      endHours.add(hoursSplit.minute(), 'minutes');

      return `${this.routingWindow.hour}h - ${endHours.format('HH:mm')}h`;
    },
    getRouting() {
      const routingIdSearch = Number(this.routingSearch);
      let isAffairFound = false;

      let user = this.user;
      if (user !== undefined) {
        if ((process.env.VUE_APP_ENV.toLowerCase() === 'dev'
          || process.env.VUE_APP_ENV.toLowerCase() === 'preprod') && !this.standalone) {
          user = clone(this.user) as User;
          user.username = 'BEUG';
        } else {
          user.username = user.username.toUpperCase();
        }

        const nameCode = (user.firstname === '') ? user.username : user.lastname.substring(0, 2).toUpperCase() + user.firstname.substring(0, 2).toUpperCase();
        const operator: UserEntity | undefined = UserHelper.getUserById(nameCode);
        if (operator === undefined) return;

        this.affaires.forEach((affaire) => {
          const checkRouting: GroupedRouting | RankRouting | undefined = affaire.getRoutingById(routingIdSearch);
          if (checkRouting !== undefined) {
            this.routingWindow.operator = operator;
            this.routingWindow.routingPlanning = checkRouting;
            this.routingWindow.open = true;
            isAffairFound = true;
            if (checkRouting instanceof RankRouting) {
              this.routingWindow.costCenter = checkRouting?.CostCenterObject?.Group !== undefined ? checkRouting.CostCenterObject.Group : '';
              this.routingWindow.color = checkRouting?.CostCenterObject?.Color !== undefined ? checkRouting.CostCenterObject.Color : 'black';
            } else {
              this.routingWindow.costCenter = checkRouting?.getGroup !== undefined ? String(checkRouting.getGroup) : '';
              this.routingWindow.color = checkRouting?.getGroup() !== undefined ? String(checkRouting.getGroup) : 'black';
            }
          }
        });
        if (!isAffairFound) {
          Notify.failure('Aucune tâche n\'a été trouvée.');
        }
      } else {
        Notify.failure('Aucun utilisateur connecté');
      }
    },
    validateRouting() {
      this.routingWindow.open = false;
      Notiflix.Confirm.show(
        'Validation tache',
        `Ajouter tache de\
        <br/>${this.rangeNewRouting()}`,
        'Confirmer',
        'Annuler',
        () => {
          this.addCustomRouting();
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {},
      );
    },
    addCustomRouting() {
      const routingFound = this.routingWindow.routingPlanning;
      if (!(this.routingWindow.timeToAdd >= 0.01)) {
        Notify.failure('Veuillez renseigner un temps à ajouter.');
        this.routingWindow.open = true;
        return;
      }

      const hoursToStart = parseFloat(this.routingWindow.hour.replace(':', '.'));

      if (hoursToStart > 17.00 || hoursToStart < 8.00) {
        Notify.failure('Veuillez renseigner une heure de début de journée valide.');
        this.routingWindow.open = true;
        return;
      }

      this.addWeekRouting(routingFound as RankRouting | GroupedRouting);
    },
    addWeekRouting(routingFound: GroupedRouting | RankRouting) {
      const totalTimeClocking = clone(this.routingWindow.timeToAdd as number);
      const weekRouting = new RoutingWeekPlanning(routingFound, true);
      const date = clone(this.selectedDay as Date);
      date.setHours(0, 0, 0, 0);

      weekRouting.minutes = this.formatTimeToMinute(totalTimeClocking);
      weekRouting.date = date;
      weekRouting.save(() => {
        this.addDayRouting(routingFound as RankRouting | GroupedRouting, weekRouting, this.routingWindow.operator as UserEntity);
      });
    },
    addDayRouting(routingFound: GroupedRouting | RankRouting, routeWeekPlanning: RoutingWeekPlanning, operator: UserEntity) {
      const totalTimeClocking = clone(this.routingWindow.timeToAdd as number);
      const isBoolean = clone(this.routingWindow.isfinish as boolean);
      const startDate = clone(this.selectedDay as Date);
      const hoursSplit = moment(this.routingWindow.hour, 'HH:mm');
      startDate.setHours(hoursSplit.hours(), hoursSplit.minute(), 0, 0);

      const dayPlanning = new RoutingDayPlanning(
        routeWeekPlanning,
        operator,
        startDate,
      );
      dayPlanning.minutes = this.formatTimeToMinute(totalTimeClocking);
      dayPlanning.totalTimeClocking = isBoolean ? this.formatTimeToMinute(totalTimeClocking) * 60 : 0;
      dayPlanning.startDateClocking = isBoolean ? startDate : undefined;
      dayPlanning.isFinished = isBoolean;
      dayPlanning.save(() => {
        routeWeekPlanning.routingsDayPlanning.push(dayPlanning);
        this.routingWindow.open = false;
        this.routingWindow.timeToAdd = 1.0;
        this.routingWindow.hour = '08:00';
        this.routingWindow.isfinish = false;
        this.addOneDay();
        this.removeOneDay();
      });
    },
    formatTimeToMinute(time: number) {
      const integerPart = Math.floor(time);
      const decimalPart = time % 1;
      return (integerPart * 60) + (decimalPart * 100);
    },
    isRegrouped(weekPlanning: RoutingWeekPlanning) {
      return weekPlanning.baseRouting instanceof GroupedRouting;
    },
    getBackgroundColor(weekPlanning: RoutingWeekPlanning) {
      const baseRouting = weekPlanning.baseRouting;
      if (baseRouting instanceof RankRouting) {
        return CostCenterHelper
          .getBackgroundColorFromGroup(
            baseRouting.CostCenterObject?.Group ?? '',
          );
      }

      if (baseRouting instanceof GroupedRouting) {
        return CostCenterHelper
          .getBackgroundColorFromGroup(
            baseRouting.getGroup(),
          );
      }
      return '';
    },
    addOneDay() {
      this.selectedDay = DateHelper.addDays(this.selectedDay, 1);
    },
    removeOneDay() {
      this.selectedDay = DateHelper.addDays(this.selectedDay, -1);
    },
    logout() {
      UserAuthManager.logout(true);
    },
  },
  created() {
    if (this.standalone && AffaireStore().affaires.length === 0) {
      Promise.all([
        CostCenterStore().loadCostCenters(),
        UserStore().loadAllUsers(),
      ]).then(() => {
        AffaireStore()
          .getAllJobInProgress();
      });
    }
  },
});
