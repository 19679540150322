
import { defineComponent, PropType } from 'vue';
import Affaire from '@/class/Affaire';
import AffaireRank from '@/class/AffaireRank';
import PlanificateurEventBus from '@/bus/planificateurEventBus';
import RankClockingState from '@/enum/RankClockingState';

export default defineComponent({
  name: 'TreeView',
  props: {
    job: {
      type: Affaire,
      required: true,
    },
    previewOnly: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    previewRank: {
      type: Object as PropType<AffaireRank | undefined>,
      required: false,
      default: () => undefined,
    },
  },
  methods: {
    getBackgroundColor(rank: AffaireRank) {
      switch (rank.getStateClocking()) {
        case RankClockingState.FINISHED:
          return 'bg-emerald-500';
        case RankClockingState.STARTED:
          return 'bg-blue-500';
        case RankClockingState.IN_PROGRESS:
          return 'bg-purple-400';
        case RankClockingState.NOT_STARTED:
        default:
          return 'bg-gray-400';
      }
    },
    getMinHeight(rank: AffaireRank | undefined): number {
      const minHeight = 40;

      if (rank === undefined) {
        return minHeight;
      }
      let childHeight = 0;
      rank.Childs.forEach((cr) => {
        childHeight += this.getMinHeight(cr);
      });

      return Math.max(minHeight, childHeight);
    },
    openRankDetail(rank: AffaireRank) {
      if (this.previewOnly) return;
      PlanificateurEventBus.trigger('showDetail', rank);
    },
    sortRank(a: AffaireRank, b: AffaireRank): number {
      if (a.Childs.length > b.Childs.length) {
        return -1;
      }
      if (a.Childs.length < b.Childs.length) {
        return 1;
      }
      return 0;
    },
    rankIsFirstOfChilds(rank: AffaireRank) {
      const parent = rank.Parent;
      if (parent === undefined) return true;
      return parent.Childs.indexOf(rank) === 0;
    },
    rankIsLastOfChilds(rank: AffaireRank) {
      const parent = rank.Parent;
      if (parent === undefined) return true;
      return parent.Childs.indexOf(rank) === parent.Childs.length - 1;
    },

    shouldHaveBarTop(rank: AffaireRank, index: number, indexSubTree: number): boolean {
      if (this.trees[index][indexSubTree].indexOf(rank) === 0) {
        return false;
      }
      if (rank.Childs.length === 0) {
        if (this.rankIsFirstOfChilds(rank)) {
          return false;
        }
      }
      return true;
    },

    shouldHaveBarBottom(rank: AffaireRank, index: number, indexSubTree: number): boolean {
      if (this.trees[index][indexSubTree].indexOf(rank)
        === this.trees[index][indexSubTree].length - 1) {
        return false;
      }
      if (rank.Childs.length === 0) {
        if (this.rankIsLastOfChilds(rank)) {
          return false;
        }
      }
      return true;
    },
  },
  computed: {
    trees() {
      const result: Array<Array<Array<AffaireRank | undefined>>> = [];
      const addChild = (rank: AffaireRank | undefined, index: number, level: number) => {
        if (result[index][level] === undefined) {
          result[index][level] = [];
        }
        result[index][level].push(rank);

        if (rank !== undefined && rank.Childs.length === 0) {
          addChild(undefined, index, level + 1);
        }
        // Recursivity
        rank?.Childs.map((x) => x)
          .sort(this.sortRank)
          .forEach((rankChild) => addChild(rankChild, index, level + 1));
      };

      // Initiator
      let indexTree = -1;
      this.job.Ranks.map((x) => x)
        .sort(this.sortRank)
        .forEach((rank) => {
          result.push([]);
          indexTree += 1;
          addChild(rank, indexTree, 0);
        });

      // console.log(result[1][2].forEach((a) => console.log(a.Childs.length)));
      result.forEach((c) => c.pop());
      return result;
    },
  },
});
