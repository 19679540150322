class RankHelper {
  static getColorLevel(level: number) {
    switch (level) {
      case 0:
        return 'bg-blue-600';
      case 1:
        return 'bg-blue-400';
      case 2:
        return 'bg-blue-300';
      default:
        return 'bg-blue-600';
    }
  }

  static getColorTextLevel(level: number) {
    switch (level) {
      case 0:
        return 'text-blue-600';
      case 1:
        return 'text-blue-500';
      case 2:
        return 'text-blue-400';
      default:
        return 'text-blue-600';
    }
  }
}

export default RankHelper;
